import React from "react";

const Insta = () =>{
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="none"
          viewBox="0 0 19 19"
        >
          <path
            fill="currentColor"
            d="M13.857 0H5.143A5.149 5.149 0 000 5.143v8.714A5.149 5.149 0 005.143 19h8.714A5.149 5.149 0 0019 13.857V5.143A5.149 5.149 0 0013.857 0zM9.5 14.695A5.201 5.201 0 014.305 9.5 5.201 5.201 0 019.5 4.305 5.201 5.201 0 0114.695 9.5 5.201 5.201 0 019.5 14.695zm5.32-9.165a1.537 1.537 0 01-1.536-1.535c0-.847.689-1.535 1.535-1.535.847 0 1.536.688 1.536 1.535 0 .846-.69 1.535-1.536 1.535z"
          ></path>
          <path
            fill="currentColor"
            d="M9.5 5.418A4.086 4.086 0 005.417 9.5a4.086 4.086 0 004.081 4.081A4.086 4.086 0 0013.581 9.5c0-2.25-1.831-4.082-4.082-4.082zM14.82 3.574a.422.422 0 100 .843.422.422 0 000-.843z"
          ></path>
        </svg>
    );
}


export default Insta;